header {
    font-family: 'Open Sans', sans-serif;
    background: #EFEFEF;
    height: 50px;
    width: 100%;
}

.headerContent {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    font-weight: bold;
    padding-top: 6px;
}

header img {
    height: 30px;
    margin-top: 0;
    margin-right: 5px;
}