.left-form {
    margin-top: 25px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.options {
    display: flex;
    justify-content: center; /* This centers the items horizontally */
    width: 100%; /* Make sure it takes the full width available */
}

.manageButton {
    margin-top: 0px !important;
    background-color: #36A9FF !important;
}

.manageText {
    padding-bottom: 0px;
    font-size: 13px;
    font-weight: 500;
}

.unsubscribeLink {
    color: #36A9FF !important;
}

.MuiSnackbar-anchorOriginTopCenter {
    top: 60px !important;
}