.left-form {
    margin-top: 25px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    text-align: center
}

.hero-image-container {
    overflow: hidden;
}

.hero-image {
    height: 100vh !important;
    display: none;
    padding-left: 20px;
}

@media (min-width: 800px) and (min-height: 550px) {
    .App {display: flex;}
    .hero-image {display: block;}
}

.logo {
    display: block;
    margin: 0px auto 25px auto;
    width: 10%;
}

.crosstownNav {
    position: fixed !important;
    left: 5px;
    top: 5px;
}

.signUpButton {
    margin-top: 10px !important;
    background-color: #36A9FF !important;
}

.style_captchaContainer__LdFYB{
    justify-content: center;
}