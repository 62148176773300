.Unsubscribe{
    margin: 50px auto 0 auto;
    text-align: center;
}

.socialMediaIcons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.MuiSvgIcon-colorPrimary {
    color: #36A9FF !important;
}